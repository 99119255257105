import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const image_url = data.contentfulAsset.file.url;

    return (
      <Layout location="./about" title={siteTitle}>
        <SEO title="About Us" keywords={[`painting`, `historical`]} />
        <div className="left">
          <p>
            I am Sean R. Autrey and I have been the owner of New Hue painting
            since 1990, painting and restoring homes in Detroit, Ann Arbor,
            Grosse Point and other communities throughout Southeastern Michigan.
            I have painted all types of homes (interior and exterior) and
            businesses over the years, both modern and historic. Historic homes
            and their unique needs have increasingly become my specialty. I am
            known for my meticulous standards and eye for detail. I have kept my
            business small enough to enable my craftsmen and me to produce
            quality work that meets the expectations of both my clients and me.
          </p>
        </div>
        <div className="right">
          <img src={image_url} alt="A beautiful dresser" />
        </div>
      </Layout>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAsset(title: { eq: "about-content" }) {
      file {
        url
      }
    }
  }
`;
